import React from 'react';
import { connect } from "react-redux";
import { setInventory } from '../redux/actions/inventoryactions';
import axios from 'axios';
import '../style/table.css';
import {baseurl} from '../config.js';

const rooms = [
    {id:0,name:'Montgomery'},
    {id:1,name:'98th'},
    {id:2,name:'SF OSFT'},
    {id:3,name:'SF Guad'},
    {id:4,name:'SF Cerrillos'},
    {id:5,name:'Espanola South'},
    {id:6,name:'Espanola North'},
    {id:7,name:'Taos 1033'},
    {id:8,name:'Taos Cannabis Company'}
]

class Home extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      inven:[],
      locationid:0
    }
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }
  async componentDidMount() {
    try {
      var inven = await axios.post(baseurl + '/inventory/getinventorybyroomid',{roomid:this.state.locationid});
      console.log('RECEIRD HERE',inven)
      this.setState({
        inven:inven.data,
        loading:false
      })
  
    } catch(error) {
      console.log('error locations',error)
    }
  }
  async handleSelectChange(event) {
    try {
      this.setState({ locationid: event.target.value, loading:true });
      var inven = await axios.post(baseurl + '/inventory/getinventorybyroomid',{roomid:event.target.value});
      this.setState({
        inven:inven.data,
        loading:false
      })

    } catch(error) {
      console.log(error)
    }
  
  }
  render() {
    console.log(this.state)
    if(this.state.inven.length > 0) {
      var c =  Object.values(this.state.inven.reduce((acc,product)=>{
        if (!acc[product.productid]) {
          acc[product.productid] = { ...product, quantity:Number(product.quantity) };
        } else {
          acc[product.productid].quantity += Number(product.quantity);
        }
        return acc;
      },{}))
  
      console.log('THE C IS HERE',c)
  
    }
    return (
      <div>
      <div className="selector">
      <select 
        onChange={this.handleSelectChange}
        value={this.state.locationid}
      >
        {rooms.map(r=>{
          return (
            <option key={r.id} value={r.id}>
              {r.name}
            </option>
          )
        })}
      </select>
    </div>
      {this.state.loading ? (
        <div>loading inven</div>
      ) : ( 

        <div>
        <div>
        <table>
          <thead>
            <th>product id</th>
            <th>name</th>
            <th>quantity</th>
          </thead>
          <tbody>
        //for uncombined, sort this.state.inven
          {c.sort((a,b)=>a.name.localeCompare(b.name)).map(i=>{
            return (
              <tr key={i.id}>
                <td>{i.productid}</td>
                <td>{i.name}</td>
                <td>{Math.round(i.quantity * 100) / 100}</td>
              </tr>
            )
          })}
          </tbody>
        </table>

        </div>
      </div>

      )}
</div>

    )
  }
}
const mapStateToProps = state => ({
  locations:state.locations
})
export default connect(mapStateToProps, {
  setInventory
})(Home);


