import React from 'react';
import axios from 'axios';
import '../style/table.css';
import moment from 'moment';

const sales_rooms = [
  {id:1,name:'98th street'},
  {id:2,name:'montgomery'},
  {id:4,name:'cerrillos'},
  {id:5,name:'guadalupe'},
  {id:6,name:'old sf trail'},
  {id:7,name:'south riverside'},
  {id:8,name:'1033 paseo'},
  {id:9,name:'tcc'},
  {id:13,name:'north riverside'},
]

const locationtotal = (d) => {
var s = d.reduce((acc, item) => {
  // Check if the location already exists in the accumulator
  if (acc[item.location]) {
    acc[item.location] += Number(item.total); // Add to the existing location sales
  } else {
    acc[item.location] = Number(item.total); // Initialize the sales for new location
  }
  return acc;
}, {});
const mappedArray = Object.entries(s).map(([key, value]) => {
  return {location:key, total:value};
});
console.log('THE S is',mappedArray)
return mappedArray
}

class About extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      sales:[]
    }
  }
  componentDidMount() {
  }

  getroom = (d) => {
    var r = sales_rooms.filter(e=>e.id == d);
    return r[0].name;
  }

  render() {
    return this.props.loading ? (
      <div className="frame_interior">loading...</div>
    ) : (
      <div className="frame_interior">
                <table>
          <thead>
            <th>location</th>
            <th>time</th>
            <th>strain</th>
            <th>price</th>
            <th>total with tax</th>
          </thead>
          <tbody>
        //for uncombined, sort this.state.inven
          {this.props.sales.map(s=>{
            return (
              <tr key={s.id}>
                <td>{this.getroom(s.location)}</td>
                <td>{moment(s.updated_on).format('HH:mm A')}</td>
                <td>{s.strain}</td>
                <td>{s.price_post_discount}</td>
                <td>{s.total}</td>
              </tr>
            )
          })}
          </tbody>
        </table>
          <div style={{marginTop:50}}>totals: {Math.round(this.props.sales.reduce((total, item) => total + Number(item.total), 0)*100)/100}</div>
          <div>count: {this.props.sales.length}</div>
          <div style={{marginTop:0}}>order average: {(Math.round(this.props.sales.reduce((total, item) => total + Number(item.total), 0)*100)/100) / this.props.sales.length}</div>
          <div style={{marginTop:0}}>yesterday at this time: {Math.round(this.props.yesterday.reduce((total, item) => total + Number(item.total), 0)*100)/100}</div>
          <div style={{marginTop:0}}>yesterday order average: {(Math.round(this.props.yesterday_total.reduce((total, item) => total + Number(item.total), 0)*100)/100)/this.props.yesterday_total.length}</div>

      </div>
    )
  }
};

export default About;

