import React from 'react';

import '../style/table.css';

const d = (data) => {
    var strainCount = data.reduce((acc, obj) => {
        acc[obj.strain] = (acc[obj.strain] || 0) + 1;
        return acc;
      }, {});
      const sortedStrains = Object.entries(strainCount).sort((a, b) => b[1] - a[1]);
      const topTenStrains = sortedStrains.slice(0, 10);
      return topTenStrains;
    }

  
 // Step 2: Convert to an array of [strain, count] and sort by count
// const sortedStrains = Object.entries(strainCount).sort((a, b) => b[1] - a[1]);


export default class TopSellers extends React.Component {
    constructor(props) {
        super(props)
    }

    

    render() {
        return this.props.loading ? (<div className="frame_interior">loading...</div>):(
            <div className="frame_interior">
            <table>
                <thead>
                <th>item</th>
                <th>count</th>
                </thead>

                <tbody>
                    {d(this.props.sales).map(e=>{
                        return (
                            <tr><td>{e[0]}</td><td>{e[1]}</td></tr>
                            
                        )
                    })}
                </tbody>

            </table>
            </div>
        )
    }
}


