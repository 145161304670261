import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Home from './components/Home';
import Sales from './components/Sales';

function App() {
  const value = useSelector((state) => state.value);
  const dispatch = useDispatch();

  return (
    <Router>
      <div className="app_container">
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/sales">Sales</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sales" element={<Sales />} />
        </Routes>

      </div>
    </Router>
  );
}

export default App;

