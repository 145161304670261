import React, {useRef} from 'react';
import axios from 'axios';
import '../style/table.css';
import SalesList from './SalesLIst';
import ByStore from './ByStore';
import TopSellers from './TopSellers';
import {baseurl} from '../config.js';

class Sales extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      sales:[]
    }
    this.leftDivRef = React.createRef();
  }
  componentDidMount() {
    this.loaddata();
    // Refresh data every 60 seconds (60000 milliseconds)
    this.interval = setInterval(() => {
      this.loaddata();
      this.leftDivRef.current.scrollTop = this.leftDivRef.current.scrollHeight - this.leftDivRef.current.clientHeight;
    }, 30000);
  }

  loaddata = async () => {
    try {

    var sales = await axios.get(baseurl + '/sales/running');
    this.setState({
      sales:sales.data.today,
      yesterday:sales.data.yesterday,
      yesterday_total:sales.data.yesterday_total,
      loading:false
    })
    this.leftDivRef.current.scrollTop = this.leftDivRef.current.scrollHeight - this.leftDivRef.current.clientHeight;

  } catch(error) {
    console.log(error)
  }

  }

  render() {
    console.log(this.state)
    return this.state.loading ? (
      <div>loading...</div>
    ) : (
      <div className="frame">
        <div className="frame_left" ref={this.leftDivRef}>
          <SalesList sales={this.state.sales} yesterday={this.state.yesterday} yesterday_total={this.state.yesterday_total} loading={this.state.loading}/>
        </div>
        <div className="frame_right">
        <ByStore sales={this.state.sales} yesterday={this.state.yesterday} yesterday_total={this.state.yesterday_total} loading={this.state.loading}/>
        <TopSellers sales={this.state.sales} loading={this.state.loading}/>
        </div>
      </div>
    )
  }
};

export default Sales;

