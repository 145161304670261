import {
    SET_INVENTORY,
    CLEAR_INVENTORY
} from './types';

export const setInventory = (data) =>{
    return {
        type:SET_INVENTORY,
        payload:data
    }
}