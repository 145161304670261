import {
    SET_INVENTORY,
    CLEAR_INVENTORY
} from '../actions/types'

const initialState = {
    inventory:[]
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_INVENTORY:
            return {
                inventory:action.payload
            }
        case CLEAR_INVENTORY:
            return {
                inventory:[]
            }
        default:
            return state;
    }
}





