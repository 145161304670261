import React from 'react';
import axios from 'axios';
import '../style/table.css';

const sales_rooms = [
  {id:1,name:'98th street'},
  {id:2,name:'montgomery'},
  {id:4,name:'cerrillos'},
  {id:5,name:'guadalupe'},
  {id:6,name:'old sf trail'},
  {id:7,name:'south riverside'},
  {id:8,name:'1033 paseo'},
  {id:9,name:'tcc'},
  {id:13,name:'north riverside'},
]

const locationtotal = (d) => {
var s = d.reduce((acc, item) => {
  // Check if the location already exists in the accumulator
  if (acc[item.location]) {
    acc[item.location] += Number(item?.total); // Add to the existing location sales
  } else {
    acc[item.location] = Number(item?.total); // Initialize the sales for new location
  }
  return acc;
}, {});
const mappedArray = Object.entries(s).map(([key, value]) => {
  return {location:key, total:value};
});
return mappedArray
}

class About extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      sales:[]
    }
  }
  componentDidMount() {
  }

  getroom = (d) => {
    var r = sales_rooms.filter(e=>e.id == d);
    return r[0].name;
  }
  t = (l,y,z) => {
    let w = []
    let ntotal = 0; 
    let yt = 0;
    let ytt = 0;

    sales_rooms.map((s,i)=>{
      var now = l.filter(e=>e.location == s.id);
      var yester = y.filter(e=>e.location == s.id);
      var yester_total = z.filter(e=>e.location == s.id);
      ntotal += Number(now[0]?.total);
      yt = yt + Number(yester[0]?.total);
      ytt = ytt + Number(yester_total[0]?.total);
      var  obj = {
        id:s.id,
        now: now[0]?.total,
        yesterday:yester[0]?.total,
        yesterday_total: yester_total[0]?.total
      }
      w.push(obj)
    })
    var obj = {
      id:13,
      now:ntotal,
      yesterday:yt,
      yesterday_total : ytt
    }
    w.push(obj)
    return w;
  }

  render() {
    var l = locationtotal(this.props.sales)
    var y = locationtotal(this.props.yesterday);
    var z = locationtotal(this.props.yesterday_total);
    var a = this.t(l,y,z)
    return this.props.loading ? (
      <div className="frame_interior">loading...</div>
    ) : (
      <div className="frame_interior">
         {/* <table>
            <thead>
              <th>location</th>
              <th>total</th>
              <th>total</th>

            </thead>
          {l.sort((a,b)=>a.total - b.total).map(e=>{
            return (
              <tr>
                <td>{this.getroom(e.location)}</td>
                <td>{Math.round(e.total * 100) / 100}</td>
                <td>{Math.round(e.total * 100) / 100}</td>
              </tr>
            )
          })}

          </table> */}
          <table>
            <thead>
              <th>location</th>
              <th>current</th>
              <th>vs. yesterday</th>
              <th>yesterday total</th>
            </thead>
            <tbody>
          {a.sort((a,b)=>a.now - b.now).map(e=>{
            return (
              <tr>
                <td>{this.getroom(e.id)}</td>
                <td style={{
                  color:e.now < e.yesterday ? 'red' : 'green',
                  fontWeight:e.now < e.yesterday ? 400 : 900
                  }}>{Math.round(e.now * 100) / 100}</td>
                <td>{Math.round(e.yesterday * 100) / 100}</td>
                <td>{Math.round(e.yesterday_total * 100) / 100}</td>

              </tr>
            )
          })}
</tbody>
          </table>

        </div>
    )
  }
};

export default About;

